// Import the functions you need from the SDKs you need
import { initializeApp, getApps, getApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAvwizRwugmUKdgtj87NpDdSuyDhIrRubs",
  authDomain: "faithcc-d7c45.firebaseapp.com",
  databaseURL: "https://faithcc-d7c45-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "faithcc-d7c45",
  storageBucket: "faithcc-d7c45.appspot.com",
  messagingSenderId: "441837356740",
  appId: "1:441837356740:web:d52ba9371fd7ac2412eb91",
  measurementId: "G-17BHTJ3BJ1"
};


// Initialize Firebase
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage}; 