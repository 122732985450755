// src/components/DocumentLibrary.js
import React, { useRef, useState, useEffect } from 'react';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes, getStorage, listAll, deleteObject } from 'firebase/storage';
import { db } from '../firebase/firebase';
import '../assets/styles/DocumentLibrary.css';
import MenuBar from './MenuBar'; // Import the MenuBar component
import logo from '../assets/images/FCC logo new.jpg';
import icon from '../assets/images/file.svg';

const DocumentLibrary = () => {
  const [currentPath, setCurrentPath] = useState('documents/');
  const [items, setItems] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [title, setTitle] = useState('');
  const [newFolderName, setNewFolderName] = useState('');
  const inputRef = useRef(null);
  const storage = getStorage();

  useEffect(() => {
    fetchItems(currentPath);
  }, [currentPath]);

  const fetchItems = async (path) => {
    try {
      const storageRef = ref(storage, path);
      const result = await listAll(storageRef);

      // Fetch file details and map into items
      const filePromises = result.items.map(async (itemRef) => {
        const url = await getDownloadURL(itemRef);
        return {
          name: itemRef.name,
          url,
          type: 'file',
          size: 'Unknown Size', // Placeholder for size
          lastModified: 'Unknown Date', // Placeholder for last modified
          ref: itemRef, // Store the reference for delete action
        };
      });

      // Map folders
      const folderPromises = result.prefixes.map((folderRef) => ({
        type: 'folder',
        name: folderRef.name,
        path: folderRef.fullPath,
      }));

      const files = await Promise.all(filePromises);
      const folders = await Promise.all(folderPromises);
      setItems([...folders, ...files]);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
      setTitle(e.target.files[0].name);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Please select a file to upload.');
      return;
    }
    const fileRef = ref(storage, `${currentPath}${selectedFile.name}`);
    try {
      await uploadBytes(fileRef, selectedFile);
      const fileURL = await getDownloadURL(fileRef);
      await addDoc(collection(db, 'documents', 'thumbnails', 'faithcc'), {
        title: selectedFile.name,
        url: fileURL,
        serverTime: serverTimestamp(),
        modifiedBy: 'Current User',
      });
      alert('File uploaded successfully!');
      setSelectedFile(null);
      setTitle('');
      inputRef.current.value = null;
      fetchItems(currentPath);
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error uploading file. Please try again.');
    }
  };

  const handleDelete = async (fileRef) => {
    try {
      await deleteObject(fileRef);
      alert('File deleted successfully!');
      fetchItems(currentPath);
    } catch (error) {
      console.error('Error deleting file:', error);
      alert('Error deleting file. Please try again.');
    }
  };

  const handleCreateFolder = async () => {
    if (!newFolderName) {
      alert('Please enter a folder name.');
      return;
    }
    const folderRef = ref(storage, `${currentPath}${newFolderName}/.keep`);
    try {
      const emptyBlob = new Blob([''], { type: 'text/plain' });
      await uploadBytes(folderRef, emptyBlob);
      alert('Folder created successfully!');
      setNewFolderName('');
      fetchItems(currentPath);
    } catch (error) {
      console.error('Error creating folder:', error);
      alert('Error creating folder. Please try again.');
    }
  };

  const handleFolderClick = (path) => {
    setCurrentPath(`${path}/`);
  };

  const handleGoBack = () => {
    const pathSegments = currentPath.split('/').filter(Boolean);
    if (pathSegments.length > 1) {
      pathSegments.pop(); // Remove the last segment
      setCurrentPath(pathSegments.join('/') + '/');
    }
  };

  return (
    <div className="document-library-container">
      <MenuBar />

      <header className="document-library-header flex items-center justify-between p-4 bg-gray-200 shadow-md">
        <div className="logo-container flex items-center">
          <img src={logo} alt="Logo" className="w-12 h-12 mr-4" />
        </div>
        <div className="upload-section flex items-center space-x-4">
          <input
            type="file"
            ref={inputRef}
            onChange={handleFileChange}
            className="file-input hidden"
          />
          <button
            onClick={() => inputRef.current.click()}
            className="select-button bg-blue-500 text-white px-4 py-2 rounded"
          >
            Select File/Folder
          </button>
          <button
            onClick={handleUpload}
            className="upload-button bg-green-500 text-white px-4 py-2 rounded"
          >
            Upload
          </button>
          <input
            type="text"
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            placeholder="New Folder Name"
            className="folder-input px-2 py-1 border rounded"
          />
          <button
            onClick={handleCreateFolder}
            className="create-folder-button bg-blue-600 text-white px-4 py-2 rounded"
          >
            Create Folder
          </button>
        </div>
      </header>

      <div className="navigation-controls p-2">
        {currentPath !== 'documents/' && (
          <button
            onClick={handleGoBack}
            className="go-back-button bg-gray-500 text-white px-2 py-1 rounded"
          >
            Go Back
          </button>
        )}
        <span className="current-path text-gray-700 ml-4">{currentPath}</span>
      </div>

      <div className="document-table-container mt-4 p-4 bg-white shadow-md rounded">
        <table className="document-table">
          <thead>
            <tr>
              <th><input type="checkbox" /></th>
              <th>Name</th>
              <th>Size</th>
              <th>Type</th>
              <th>Last Modified</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td><input type="checkbox" /></td>
                <td>
                  {item.type === 'folder' ? (
                    <button
                      onClick={() => handleFolderClick(item.path)}
                      className="text-blue-500 hover:underline flex items-center"
                    >
                      📁 {item.name}
                    </button>
                  ) : (
                    <>
                      <div className="flex items-center">
                        <img src={icon} alt="File Icon" className="file-icon" />
                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                          {item.name || 'Untitled'}
                        </a>
                      </div>
                    </>
                  )}
                </td>
                <td>{item.size}</td>
                <td>{item.type}</td>
                <td>{item.lastModified}</td>
                <td>
                  {item.type === 'file' && (
                    <>
                      <button
                        onClick={() => handleDelete(item.ref)}
                        className="delete-button bg-red-500 text-white px-2 py-1 rounded"
                      >
                        Delete
                      </button>
                      <button
                        onClick={() => window.open(item.url, '_blank')}
                        className="view-button bg-blue-500 text-white px-2 py-1 rounded"
                      >
                        View
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DocumentLibrary;
