import React from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, Link } from 'react-router-dom';
import Login from './components/Login';
import Home from './components/Home';
import DocumentLibrary from './components/DocumentLibrary';
import About from './components/About';
import Messages from './components/Messages';
import Calendar from './components/Calendar';
import { auth } from './firebase/firebase'; // Make sure this is imported
import { useAuthState } from 'react-firebase-hooks/auth';

function App() {
  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/document-library" element={<DocumentLibrary />} /> {/* Ensure the path matches exactly */}
          <Route path="/about" element={<About />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/calendar" element={<Calendar />} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;